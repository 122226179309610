// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$screen-sm-min:					769px; // iPad in portait orientation uses mobile layout

// Section margins
$main-margin: 					3em;

// Colors
$brand-primary:         #27ae60;
$dark-background:				#000;

$light-text: 					  #fff;
$dark-text:							#808284;
$gray-accent: 					#6d6e70;

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins-regular.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins-light.ttf') format('truetype');
	font-weight: 100;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins-medium.ttf') format('truetype');
	font-weight: bolder;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins-semibold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins-bold.ttf') format('truetype');
	font-weight: bold;
}
