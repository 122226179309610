.home {
	header.banner {
		.navigation-bar {
			background-image: none !important;

		}
	}
	.wrap {
		margin-top: -100px;
	}
	.hero {
		max-height: 650px;
		img.attachment-post-thumbnail {
			width: 100%;
			height: auto;
			opacity: 1 !important;
		}
		video {
			width: 100%;
			height: auto;
			position: relative;
			z-index: -1;
		}
	}

	.home-content {
		margin-top: $main-margin;
	}
}


.content {
	.main{
    margin: 2em 0;
	}
	.container {
		margin: 2em auto;
	
		.wp-caption {
			border: none;
			border-radius: 0;
			padding: 0;
			float: left;
			max-width: 50%;
			.wp-caption-text {
				width: 100%;
				text-align: center;
				color: $light-text;
				background: $dark-background;
			}
		}
		.staff-member {
			width: 100%;
			float: left;
			margin-right: 20px;
		}
	}
	.page-main-content {
		font-size: 16px;
		line-height: 2em;
		text-align: center;
		img {
			max-width: 100%;
		}
	}
}

.mobile-page-header {
	text-transform: uppercase;
	letter-spacing: 0.2em;
	h3 {
		margin-top: 0;
	}
}

.page-template-two-column {
	.content {
		.container {
			.page-main-content {
				line-height: 2em;
				text-align: inherit;
			}			
		}
	}
	.column-wrap {
		> div {
			margin-bottom: 1em;
		}
	}
}
.section-two-wrap {
	.page-section-two {
		img {
			max-width: 100%;
		}
		.wp-caption {
			border: none;
			padding: 0;
			width: 100% !important;
			img {
				width: 100%;
			}
		}
		.wp-caption-text {
			width: 100%;
			text-align: center;
			color: $light-text;
			background: $dark-background;
		}
	}
}

.events-header {
	margin-left: -15px;
	margin-right: -15px;
}

div.wpcf7 {
	background: #fff;
	padding: 30px !important;
	color: #000;
	border: 1px solid #000;
	overflow: hidden;
	br {
		display: none;
	}
	p {
		margin: 0;
	}
	label {
		display: block;
		margin-bottom: 15px;
		text-align: right;
	}
	input, textarea {
		border-radius: 0;
		width: 100%;
		padding: 10px 15px;
		color: #000;
		background: #fff;
		border: 1px solid #000;
	}
	input[type="submit"], input[type="checkbox"] {
		width: inherit;
		float: right;
		border-radius: 0;
	}
	input[type="submit"] {
		border: 1px solid #000;
		background-color: #fff;
		color: #000;
		padding: 10px 15px 9px;
	}
	input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
		color: #000;
	}
	
	input::-moz-placeholder, textarea::-moz-placeholder {
		color: #000;
	} 
	
	input:-moz-placeholder, textarea:-moz-placeholder {
		color: #000;
	}
	
	input:-ms-input-placeholder, textarea:-ms-input-placeholder {
		color: #000;
	}

	.wpcf7-list-item {
		.wpcf7-list-item-label{
			display: none;
		}
	}
}

#magnify-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	display: none;
	
	#magnifier {
		height: 100%;
		width: 100%;
	}
	
	.close-magnify {
		display: block;
		width: 40px;
		height: 40px;
		background-image: url('../images/close-icon.png');
		background-repeat: no-repeat;
		background-size: 40px;
		position: absolute;
		top: 20px;
		left: 20px;
		opacity: .75;
		z-index: 999;
	}
	.controls {
		position: absolute;
		width: auto;
		bottom: 20px;
		left: 50%;
		margin-left: -150px;
		background: rgba(0,0,0,0.75);
		padding: 15px;

		.control {
			float: left;
			background-color: transparent;
			border: none;
			text-indent: -9999999px;
		}
		.zoom-range {
			width: 200px;
			margin: 3px 15px;
		}
		.zoom-out {
			display: block;
			width: 20px;
			height: 20px;
			background-image: url('../images/minus-icon.png');
			background-size: 20px;
		}
		.zoom-in {
			display: block;
			width: 20px;
			height: 20px;
			background-image: url('../images/plus-icon.png');
			background-size: 20px;
		}
		.reset {
			display: none;
			margin: 15px auto;
		}
	}
}

.page-gallery {
	.flexslider {
		margin: 0;
	}
	.flex-direction-nav {
		a {
			text-indent: -9999999px;
			background-size: 40px;
			background-repeat: no-repeat;
			opacity: 1 !important;
		}
		a:before {
			content: none !important;	
		}
		a.flex-next {
			right: 0;
			background-image: url('../images/slider-arrow-right.png');
		}
		a.flex-prev {
			left: 0;
			background-image: url('../images/slider-arrow-left.png');
		}
	}
	.slides {
		li {
			.flex-caption {
				background: #000;
				color: #fff;
				min-height: 30px;
				text-align: center;
				padding: 5px;
			}
		}
	}
}

@media (max-width: $screen-sm-min) {
	.home {
		.wrap {
			margin-top: 0;
		}
	}
	.page-section-two {
		.wp-caption {
			.wp-caption-text {
				display: block;
			}
		}
	}
	.page-gallery {
		.slides {
			li {
				background-image: none !important;
			}
		}
	}
	div.wpcf7 {
		margin-bottom: $main-margin;
	}
}

@media (min-width: $screen-sm-min) {
	.content {
		.container {
			.staff-member {
				width: inherit;
			}
		}
	}
	.section-two-wrap {
		.page-section-two {
			.wp-caption {
				float: left;
				max-width: 485px;
				margin-left: -15px;
				max-width: 375px;
				max-height: 225px;
		    overflow: hidden;
				.wp-caption-text {
					display: none;
					width: 100%;
					text-align: center;
					color: $light-text;
					background: $dark-background;
				}
			}
			.wp-caption + .wp-caption {
				float: right;
				margin-right: -15px;
				margin-left: 0;
			}
		}
	}
	.page-gallery {
		padding: 0 15px;
		max-height: 297px;
		.flexslider {
			max-height: 297pxpx;
			margin: 0 0 $main-margin;
			.slides {
				li {
					background-size: cover;
					height: 297px;
					img {
						visibility: hidden;
						height: 267px;
					}
				}
			}
		}
	}

	#magnify-wrapper {
		.close-magnify {
			width: 80px;
			height: 80px;
			background-size: 100%;
		}
	}
}

@media (min-width: $screen-md-min) {
	.section-two-wrap {
		.page-section-two {
			.wp-caption {
				max-width: 485px;
			}
		}
	}
	.page-gallery {
		max-height: 445px;
		.flexslider {
			max-height: 445px;
			.slides {
				li {
					background-size: cover;
					height: 445px;
					img {
						visibility: hidden;
						height: 415px;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.section-two-wrap {
		.page-section-two {
			.wp-caption {
				max-width: 585px;
			}
		}
	}
	.page-gallery {
		max-height: 544px;
		.flexslider {
			max-height: 544px;
			.slides {
				li {
					background-size: cover;
					height: 544px;
					img {
						visibility: hidden;
						height: 514px;
					}
				}
			}
		}
	}
}


