#primary-navigation {
	#menu-primary {
		margin-top: 3px;
		.menu-item {
			font-weight: 100;
			letter-spacing: .15em;
			a {
				color: #fff;
			}
		}
		.active.menu-item {
			font-weight: 400;
		}
	}
	a {
		color: #fff;
	}
}
.fixed-hidden-menu {
	
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	overflow: hidden;
	z-index: 999;
	box-shadow: 0px 0px 4px -1px #000;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	.container {
		padding-bottom: 1em;
	}
	a {
		color: #000;
	}
	.close-icon {
		width: 80px;
    height: 80px;
	}

	.nav {
		display: inline-block;
		margin: 0 auto;
		padding-left: 7px;
		float: none;
		font-size: 1.2em;
	}
}

@media (max-width: $screen-sm-min) {
	#primary-navigation {

		box-shadow: none;
		#menu-primary.nav {
			.menu-item {
				margin-bottom: 3em;
			}
		}
		#menu-social {
			margin-left: 0;
		}

		#menu-social {
			margin-top: $main-margin;
		}
		
		#menu-meta {
			margin-top: $main-margin * 2;
			font-size: .8em;
			.menu-item {

			}
		}
	}
}

.nav {
	a {
		&:focus, &:hover {
			
		}
	}
}

.nav {
	.menu-item {
		text-transform: uppercase;
		margin: 0 15px;
		a {
			padding: 0;
			&:hover {
				background-color: transparent;
			}
		}
	}
	.az-char {
		float: left;
		/*width: 30px;*/
		margin: 0;
	}
	.current-menu-item {
		text-decoration: underline;
		background-color: #eee;
	}
}	

.boxed-title-nav {
	a {
		color: #000;
	}
	.menu-divider {
		margin: 0 10px;
	}
	span.menu-divider:last-of-type {
		display: none;
	}
}


#child-page-menu {
	.nav {
		padding-left: 0;
	}
}

.child-page-nav {
	display: inline-block;
	padding-left: 0;
	list-style: none;
	float: none;
	.page_item {
		float: left;
		a {
			color: #000;
			text-transform: uppercase;
			letter-spacing: .2em;
			float: left;
			padding: 0;
			line-height: inherit;
			&:hover {
				background-color: transparent;
			}
		}
		.menu-divider {
			margin: 0 15px;
		}
	}
	.current_page_item {
		font-weight: 700;
	}
	li:last-child {
		.menu-divider {
			display: none;
		}
	}
}

.nav.icons {
	.menu-item {
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    background-size: 25px;
		float: left;
    margin: 0 1em 0 0;

		a {
			display: block;
			width: 25px;
			height: 25px;
			padding: 0px;
			&:hover {
				background-color: transparent;
			}
		}
	}
}

.subpage-header {
	.navbar-toggle {
		float: none;
	}
}

.artists-header {
	margin-bottom: 0;

	.artist-menu-control.boxed-title-wrap {
		margin-bottom: 0
	}
	.navbar-header {
		.boxed-title-box {
		}
	}
	.navbar-toggle {
		float: none;
		margin: 0;
		background: #fff;
		padding: 9px;
		border: none;
		top: 0;
		background-image: url('../images/carat-icon.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
    background-size: 15px;
    padding-right: 20px;
	}
	.glossary-menu {
		margin-bottom: 1em;
		p {
			margin: 0;
		}
		.nav {
			float: none;
			display: inline-block;
		}
		.az-char {
			a {
				padding: 8px;
			}
			span {
				position: relative;
				display: block;
				line-height: 20px;
				padding: 8px;
			}
		}
	}
}

.events-header {
	.navbar-toggle {
		float: none;
		margin: 0;
		background: #fff;
		padding: 9px;
		border: none;
		top: 0;
		background-image: url('../images/settings-icon.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
		background-size: 20px;
		padding-right: 25px;
	}
	.scope-menu {
		.nav {
			display: inline-block;
			float: none;
			margin-bottom: 1em;
			li {
				margin: 0 15px 0 0;
			}
			.menu-title {
				p {
					margin: 0;
				}
			}
			.menu-item {
				a {
					display: inline;
					color: #000;
					background-image: url('../images/checkbox-empty.png');
					background-repeat: no-repeat;
					background-position: right center;
					background-size: 20px;
					padding-right: 25px;
				}
			}
			.menu-item.current-item {
				a {
					background-image: url('../images/checkbox-filled.png');
				}
			}
		}
	}
}
#events-filter-menu {
	.filter-title {
		display: inline-block;
		margin-bottom: 1em;
		background-image: url('../images/settings-icon.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
		background-size: 20px;
		padding-right: 25px;
		margin-bottom: 1em;
	}
	.category-menu {
		margin-top: 2em;
	}
	.menu-item {
		a {
			color: #000;
			background-image: url('../images/checkbox-empty.png');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 20px;
			padding-left: 25px;
		}
	}
	.menu-item.current-item {
		a {
			background-image: url('../images/checkbox-filled.png');
		}
	}

}