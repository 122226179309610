.post {
	.entry-title {
		a {
			color: #000;
		}
	}
}
.post-featured-image {
	img {
		width: 100%;
		height: auto;
	}
}

.single-post {
	.entry-content {
		img {
			max-width: 100%;
		}
	}
}

.entry-content {
	img {
		max-width: 100%;
	}
}

.single-event {
	.event-date {
		text-transform: uppercase;
	}
	.event-location {
		padding-left: 18px;
		background-image: url('../images/location-icon.png');
		background-repeat: no-repeat;
		background-size: 13px;
		a {
			color: #000;
		}
	}
	.entry-content {
		margin-bottom: 1em;
	}
}
.event-list {
	.event {
		margin-bottom: $main-margin / 2;
	}
	.post-featured-image {
		/*min-height: 200px;*/
		max-height: 275px;
		overflow: hidden;
		a {
			display: block;
			width: 100%;
			min-height: 200px;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.event-list {
		.post-featured-image {
			height: 225px;
		}
	}
}

@media (min-width: $screen-md-min) {
	.event-list {
		.post-featured-image {
			height: 275px;
		}
	}
}