header.banner {
	color: #fff;
	
	.navbar {
		margin-bottom: 0;
		border: none;
		/*overflow: hidden;*/
	}

	.mailing-list-bar {
		background-color: #000;
		padding: 10px 0 10px;
	}

	.navbar-header {
		.header-search-wrap {
			float: left;
			height: 100%;
			.show-search {
				height: 80px;
			}
			.search-form {
				float: left;
				label {
					margin: 0;
				}
				.search-field {
					height: 80px;
					background-position: 0 50%;
			    padding-left: 25px;
			    margin-left: 25px
				}
			}
		}
	}

	.navigation-bar {
		height: auto;
		padding: $main-margin $main-margin/2 2.5em;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url('../images/default_header_bg.jpg');

		.brand {
			.primary {
				position: absolute;
				width: 300px;
			}
			.secondary {
				width: 50px;
				margin: 15px;
			}
		}

		.navbar-toggle {
			margin: 0;
			width: 80px;
			height: 80px;
			background-color: #000;
			background-image: url('../images/mobile_nav_close.png');
			border-left: 1px solid $gray-accent;
	    background-size: 23px;
	    background-repeat: no-repeat;
	    background-position: center
		}
		.navbar-toggle.collapsed {
			background-image: url('../images/mobile_nav_button.png');
	    background-size: 50px;
		}
	}

	.search-form-bar {
		position: absolute;
		top: 80px;
/*		border-top: 1px solid #6d6e70;
*/		z-index: 99;
		background: #000;
		label {
			margin-bottom: 0;
		}
		
		form {
			padding: 10px 0;
			margin-top: 3px;
		}

		.search-submit {
			background: #fff;
	    border: none;
	    color: #000;
	    text-transform: uppercase;
	    &:focus {
	    	outline: none;
	    }
	  }
	}
}

@media (max-width: $screen-sm-min) {
	header.banner {
	
		.navbar {
			max-height: 80px;
		}
		
		.search-form-bar {
			border-top: 1px solid #6d6e70;
		}

		#primary-navigation {
			background: #000;			
			/*width: 50%;*/
	    position: absolute;
	    right: 0;
	    z-index: 999;
	    margin-right: 0;
	    padding: 0em 3em 3em 3em;
		}
	
		.navbar-header {
			background: #000;
		}
	
		.navigation-bar {

			padding: 0 15px;
			.brand {
				float: left;
				display: block;
				width: 80px;
				height: 80px;
				border-right: 1px solid $gray-accent;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	header.banner {
		.search-form-bar {
			top: 0px;
			.search-field {
				width: 100%;
			}
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: 1014px) {
	header.banner .navigation-bar .brand .primary {
    width: 225px;
  }
	
	#primary-navigation #menu-primary .menu-item {
		margin: 0 5px;
		font-size: 12px;
	}
}

