html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	
	.baskerville {
		/*font-family: 'Libre Baskerville', 'Baskerville', "Georgia", "Times", serif;*/
	}

	.poppins {
		font-family: 'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	}
}

.wrap {
/*	min-height: 100%;
	margin-bottom: -260px;*/
}

h1, h2, h3, h4, h5, h6 {
	@extend .poppins;
	font-weight: bold;
}

a {
	color: $dark-text;
	&:hover {
		color: inherit;
		text-decoration: none;
		background-color: transparent;
	}
}


.dark-bg {
	background-color: $dark-background;

	a {
		color: $light-text;
	}
}

.entry-title {
	clear: both;
}

.wp-caption {
	border-radius: 0;
}

// global site sections

.offscreen {
	width: 0;
}
.collapse.width {
    height: auto;
    -webkit-transition: width 0.35s ease;
    -moz-transition: width 0.35s ease;
    -o-transition: width 0.35s ease;
    transition: width 0.35s ease;
}
.close-icon {
	display: block;
	width: 40px;
	height: 40px;
	background-image: url('../images/mobile_nav_close_black.png');
	background-repeat: no-repeat;
  background-size: 25px;
	background-position: 100% 50%;  
	text-indent: -9999px;
}

.search-icon {
	display: block;
	width: 40px;
	height: 40px;
	background-image: url('../images/mobile_nav_close.png');
	background-repeat: no-repeat;
  background-size: 23px;
	background-position: 100% 50%;  
	text-indent: -9999px;
}
.search-icon.collapsed {
	background-image: url('../images/search-icon.png');
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 20px;
}
.search-form-bar {
	.search-form {
		.search-field {
			color: $light-text;
			border: 1px solid #fff;
			border-radius: 0;
			background-color: #000;
			&:focus {
				box-shadow: none;
				border: 1px solid #fff;
			}
		}
		.search-icon {
			display: block;
			width: 20px;
			height: 20px;
			background-image: url('../images/search-icon.png');
			background-repeat: no-repeat;
			background-position: 100% 50%;
			background-size: 20px;
		}
	}
}
.search {
	.results {
		.search-form {
			background: #000;
			padding: 20px;
			color: #fff;
			label {
				line-height: 34px;
			}
			.search-field, .search-submit {
				border-radius: 0;
				width: 100%;
				&:focus {
					box-shadow: none;
				}
			}
			.form-group {
				display: block;
			}
		}
	}
}
.back-to-top-wrap {
	padding: 15px;
	text-transform: uppercase;
}

#back-to-top {
	color: #000;
	padding-right: 45px;
	background-image: url('../images/up-arrow.png');
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 15px;
}


.icons {
	.icon {
		background-repeat: no-repeat;
	}
	.artsy-icon {
		background-image: url('../images/artsy-icon.png');
	}
	.facebook-icon {
		background-image: url('../images/facebook-icon.png');
	}
	.twitter-icon {
		background-image: url('../images/twitter-icon.png');
	}
	.instagram-icon {
		background-image: url('../images/instagram-icon.png');
	}
}

.btn {
	border-radius: 0 !important;
}
.block-btn {
	display: block;
	width: 100%;
	padding: 12px 12px 10px;
	background-color: #fff;
	color: #000;
	text-transform: uppercase;
	margin-top: $main-margin / 2;
	border: 1px solid #000;

	&:hover {
		background-color: #fff;
		color: #000;
		text-decoration: none;
	}
}

.white-btn {
	display: block;
	color: #000;
	background: #fff;
	text-transform: uppercase;
	width: 100%;
	border: 1px solid #000;
	padding: 8px 12px 5px;

}

.back-btn {
	margin-bottom: $main-margin / 2;
	padding: 12px;
	background-image: url('../images/back-arrow-black.png');
	background-repeat: no-repeat;
	background-position: 12px;
	background-size: 20px;
	background-color: #fff;
	color: #000;
	border: 1px solid #000;
	text-transform: uppercase;
	&:hover {
		background-color: #fff;
		color: #000;
		text-decoration: none;
	}
}

/*
 * Home page featured video
 */
#featured-video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	.featured-video {
		margin-top: 200px;
	}
}

.hero-video {
	margin-top: 80px;
}

.show-video {
	position: absolute;
  width: 80px;
  height: 80px;
  max-height: 650px;
	display: block;
	background-image: url('../images/play-icon.png');
	background-size: 80px;
	background-repeat: no-repeat;
	background-position: center;
	text-indent: -999px;
	opacity: 0;
  transition: opacity .75s ease-out;
  -moz-transition: opacity .75s ease-out;
  -webkit-transition: opacity .75s ease-out;
  -o-transition: opacity .75s ease-out;
}
.show-video.show-fade-in {
	opacity: 1;
}

.fvp-overlay {
	position: inherit;
	.fvp-actionicon, .fvp-actionicon.play {
    width: 100%;
    height: 100%;
    max-height: 650px;
		display: block;
		background-image: url('../images/play-icon.png');
		background-size: 80px;
	}
}

/*
 * Mailchimp signup form
 */
.newsletter-form {
	text-transform: uppercase;
	margin-top: 4px;
	.mc-field-group, .mc-header {
		float: left;
		margin-right: 15px;
		margin-bottom: 15px;
	}
	.mc-field-group {
		/*margin-top: 5px;*/
	}
	input {
		background: #000;
		border: 1px solid #fff;
		color: #fff;
		padding: 5px 10px;
		max-width: 100%;
		border-radius: 0;
		font-weight: 100;
		letter-spacing: .1em;
		::-webkit-input-placeholder {
			color: #fff;
		}
	}
	input::-webkit-input-placeholder {
		color: #fff;
	}
	
	input::-moz-placeholder {
		color: #fff;
	} 
	
	input:-moz-placeholder {
		color: #fff;
	}
	
	input:-ms-input-placeholder {
		color: #fff;
	}

	input[type="submit"] {
		padding: 5px 25px;
		border-radius: 0;
		background: #000;
		border: 1px solid #fff;
		color: #fff;
	}
	.mc-header {
		font-weight: 400;
		letter-spacing: .2em;
	}
}

@media (max-width: $screen-sm-min) {
	.back-btn {
		text-align: right;
	}
	.show-video {
		margin-top: 80px !important;
	}
	
}
@media (min-width: $screen-sm-min) {
	body {
		margin: 0 0 260px;
	}
	.newsletter-form {
		.mc-field-group, .mc-header {
			margin-bottom: 0;
		}
		input {
			max-width: 150px;
		}
	}
}

@media (min-width: $screen-md-min) {
	.show-video {
		margin-top: 54px !important;
	}
	.hero-video {
		margin-top: 100px;
	}
}

.overlay-close {
	display: block;
	width: 80px;
	height: 80px;
	background-image: url('../images/close-icon.png');
	background-repeat: no-repeat;
	background-size: 100%;
	position: absolute;
	top: 20px;
	left: 20px;
	opacity: .75;
	z-index: 999;
}

.boxed-title-wrap {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: $main-margin / 2;
	height: auto;
	position: relative;
	overflow: hidden;
	padding: 0;
	
	hr {
		border-top: 1px solid #000;
		margin: 0;
		position: absolute;
    top: 50%;
    width: 100%
	}

	.boxed-title-box {
		display: block;
		border: 1px solid #000;
		text-align: center;
		background-color: #fff;
		/*background-image: url('../images/bg-line.png'), url('../images/bg-line.png');*/
		background-image: url('../images/black-pixel.png'), url('../images/black-pixel.png');
		background-repeat: no-repeat;
		background-position: 0px 50%, 100% 50%;
		background-size: 20px 1px;
	}

	.boxed-title {
		@extend .poppins;
		font-weight: 600;
		text-transform: uppercase;
		padding: 7px 0;
		margin: 0 auto;
		font-size: 17px;
		letter-spacing: .2em;
	}
}
.page-header {
	.boxed-title-wrap {
		margin-left: 0;
		margin-right: 0;
	}
}
.boxed-link {
	@extend .poppins;
	font-weight: 600;
	display: inline-block;
	margin-top: 1em; 
	text-transform: uppercase;
	color: #000;
	padding: 5px 50px;
	border: 1px solid #000;
}

.hero {
	.hero-arrows {
		height: 50px;
		margin-top: -30px;
		background-image: url('../images/down-arrow.png'), url('../images/down-arrow.png');
		background-repeat: no-repeat;
		background-position: 0px 50%, 100% 50%;
		background-size: 20px;

	}
}

/* Responsive Embeds */
.entry-content-asset {
	position: relative;
	overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  margin: 1em 0;
  iframe, object, embed {
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

/* IE10+ CSS styles go here */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.home {
		.hero {
			video {
				margin-top: -65px;
			}
		}
	}
}