body.artists, body.tax-glossary {
	.page-header {
		margin-top: 2em;
	}
	.content {
		.container {
			margin-top: 0;
		}
	}
	.featured-artist {
		.artist-link {
			img {
				opacity: 1;
			}
		}
	}
}

body.single-artist {
	.artist-artworks {
		list-style: none;
		padding: 0;
		margin-top: $main-margin / 2;

		.artist-artwork {
			margin-bottom: 15px;
			.magnify {
		    width: 100%;
				height: 196px;
		    overflow: hidden;
		    display: block;
		    text-align: center;
        background-size: contain;
		    background-repeat: no-repeat;
		    background-position: center;
				cursor: -webkit-zoom-in;
				cursor: -moz-zoom-in;
				cursor: zoom-in;
			}

			.entry-summary {
				/*min-height: 61px;*/
				strong {
					font-weight: 600;
				}
			}
			.block-btn {
				margin-top: 0;
			}
			span {
				text-align: center;
			}
		}
	}
}

.artist {
	.entry-summary {
		margin: 1em 0;
		padding: 0 .5em;
		.entry-title {
			margin-bottom: 5px;
			a {
				color: #000;
			}
		}
		p {
			margin: 0 0 1px;
		}
	}
}


.artist-list {
	clear: both;
	margin-top: $main-margin;
	.artist {
		margin-bottom: $main-margin / 2;

		.block-btn {
			margin-top: 0;
			/*padding: 8px 12px 5px;*/
		}
		
		.country {
			min-height: 20px;
		}

		.artist-image {
			overflow: hidden;
			a {
		    display: block;
		    width: 100%;
				height: 196px;
		    overflow: hidden;
        background-size: contain;
		    background-repeat: no-repeat;
		    background-position: center;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

#artists-glossary-menu {
	.az-char {
		span, a {
			position: relative;
			display: block;
			line-height: 20px;
			padding: 8px;
		}
	}
}

.featured-artist {
	position: relative;
	clear: both;
	padding-top: $main-margin;
	padding-left: 0;
	padding-right: 0;
	overflow: hidden;
	.artist-link {
		display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
		img {
			width: 100%;
			height: auto;				
			opacity: 0.5
		}
	}


	.artist-info {
		bottom: 40px;
		.inner-title {
	    display: inline-block;
	    padding: 10px 50px;
	    text-transform: uppercase;
			color: $dark-text;
			background: #000;
	    letter-spacing: .2em;
	    font-weight: 100;
		}
		
		.artist-name {
			font-weight: 400;
			color: #fff;
			a {
				color: #fff;
			}
		}

		.artist-name, .artist-excerpt {
			color: $light-text;
			a {
				color: $light-text;
			}
		}
	}
}


body.artists, body.tax-glossary {
	.featured-artists {
		overflow: hidden;
		.featured-artist {
			clear: none;
			padding: 0;
			.artist-info {
				bottom: 0;
				background: #000;
				.artist-name {

				}
				.inner-title, .artist-excerpt {
					display: none;
				}
			}
		}
	}
}

@media (max-width: $screen-sm-min) {
	.artists-header {
/*		position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;*/
    background: #fff;
    padding-top: 1.5em;
    .strikethrough-wrap {
    	width: 100%;
    	hr {
    		border-color: #000;
    		margin-bottom: -21px;
    		margin-top: 0;
    	}
    }

    .glossary-button-wrap {
    	border: 1px solid #000;
    	padding: 1px;
    }
	}
	.featured-artist {
		padding-top: 0;
		.artist-link {
			height: 220px;
			overflow: hidden;
			background-size: cover;
		}
		.artist-info {
			bottom: 20px;
			position: absolute;
			.artist-name {
				font-size: 1.5em;
				margin-top: 10px;
			}
		}
		.screen-black {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.5);
		}
	}
	body.tax-glossary .container .featured-artists {
		display: none;
	}
}

@media (min-width: $screen-sm-min) {
	body.single-artist {
		.artist-artworks {
			.artist-artwork {
				.entry-summary {
					min-height: 61px;	
				}
			}
		}
	}
	body.home {
		.featured-artist {
			.artist-info {
				position: absolute;
			}
			.screen-black {
				position: absolute;
				top: 3em;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.5);
			}
		}
	}
	body.artists > .featured-artists {
		display: none;
	}

	body.tax-glossary > .featured-artists {
		display: none;
	}

	.featured-artist {

		.artist-link {
			height: 500px;
		}
		.artist-info {

		}
	}
}