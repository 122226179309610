footer.content-info {
	width: 100%;
	min-height: 205px;
	background-color: #000;
	color: $light-text;
	font-weight: 100;
	letter-spacing: .1em;
	.brand-image {
		margin-bottom: 10px;
	}
	.nav {
		margin-top: 2em;
	}
}


@media (max-width: $screen-sm-min) {
	footer.content-info {
		/*min-height: 0;*/
		padding: 3em 0 1em 0;
	
		.brand-image {
			width: 55px;
		}
	}
}

@media (min-width: $screen-sm-min) {
	footer.content-info {
		position: absolute;
		bottom: 0;
		.brand-image {
			margin-top: $main-margin / 2;
		}
	}
}